/* .gradient-background {
    background: linear-gradient(217deg, rgba(249, 172, 39, 0.8), rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(127deg, rgba(135, 135, 135, 0.581), rgba(0, 255, 0, 0) 70.71%),
        linear-gradient(336deg, rgba(235, 242, 28, 0.8), rgba(0, 0, 255, 0) 70.71%);
} */

.gradient-background {
    background: linear-gradient(217deg, #a3cef1, rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(127deg, #e7ecef, rgba(0, 255, 0, 0) 70.71%),
            linear-gradient(336deg, #8b8c89, rgba(0, 0, 255, 0) 70.71%);
}
/* .gradient-background {
                                                                                                                                background: linear-gradient(217deg, #FFC300, rgba(255, 0, 0, 0) 60.71%),
                                                                                                                                    linear-gradient(127deg, #ededed, rgba(0, 255, 0, 0) 70.71%),
                                                                                                                                    linear-gradient(336deg, #001D3D, rgba(0, 0, 255, 0) 70.71%);
                                                                                                            } */